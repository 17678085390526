const crFeatureKeys = {
  offerBuilder: {
    givenOfferDistribution: 'offerBuilder_givenOfferDistribution_frontend',
    creationFlow: 'offerBuilder_creationFlow_frontend',
    changeRequestApprovalPublishFlow:
      'offerBuilder_changeRequestApprovalPublishFlow_frontend',
  },
  consumer: {
    details: 'offerBuilder_consumerDetails_frontend',
  },
} as const;

const paymentsFeatureKeys = {
  admin: {
    paymentsAdmin: 'admin_enablePaymentAdmin_frontend',
  },
} as const;

const a4aFeatureKeys = {
  admin: {
    a4aRoute: 'admin_enableA4Aroute_frontend',
    a4aHelloWorld: 'admin_enableA4AhelloWorld_frontend',
    a4aAlleElementsLibraryEnabled:
      'admin_enableA4AalleElementsLibrary_frontend',
    a4aBusinessPageViewEnabled: 'admin_enableA4ABusinessPageView_frontend',
    a4aNewGlobalNavigationEnabled:
      'admin_enableA4ANewGlobalNavigation_frontend',
    a4aBlockCodeBannerEnabled: 'admin_blockCodeBanner_frontend',
    patientSummary: {
      offerHistory: 'admin_patient_summary_offer_history',
      profileActivityHistory: 'admin_patient_summary_profile_activity_history',
      pointsCap: 'admin_patient_summary_points_cap',
    },
    managePatientLinks: {
      activateAccount: 'admin_manage_patient_links_activate_account',
      mergePatient: 'admin_manage_patient_links_merge_patient',
      unmergePatient: 'admin_manage_patient_links_unmerge_patient',
      preferredProvider: 'admin_manage_patient_links_preferred_provider',
    },
    transactionOptions: {
      viewEdits: 'admin_transaction_options_view_edits',
      editTransaction: 'admin_transaction_options_edit_transaction',
    },
    offerBuilder: {
      a4aOfferListPage: 'admin_a4a_offer_builder_list_page',
      domainOffers: 'admin_offer_builder_domain_offers',
      giftCardsChangeRequestEndDateToggle:
        'admin_offer_builder_giftcard_change_requests_enddatetoggle',
      repDispensedFlashEnabled:
        'admin_offer_builder_rep_dispensed_flash_enabled',
      isAllBrandsRequired: 'admin_offer_builder_dual_brand_selection_enabled',
    },
    offerFlex360: {
      transferRebates: 'admin_offer_flex_360_transfer_rebates',
    },
    adminCheckoutTracking: 'admin_checkout_tracking',
    giftCardDetailsContentfulIsi: 'all_cr_gift_card_details_contentful_isi',
    communicationPreferences: {
      push: 'consumer_communication_preferences_push',
      sms: 'consumer_communication_preferences_sms',
      enabledEmail: 'consumer_communication_preferences_enabled_email',
      saveButton: 'admin_communication_preferences_save_button',
    },
    patientOffers: {
      ngrp: 'admin_patient_offers_ngrp',
    },
    core: {
      showPracticeInviteNewBusinessOwner:
        'admin_core_show_practice_invite_new_business_owner',
      showConsumerDeactivationAndDeletion:
        'admin_core_show_consumer_deactivation_and_deletion',
    },
    practice: {
      locationTransferTransactionStatusCard:
        'admin_show_practice_location_transfer_transaction_status_card',
    },
    useIamService: 'admin_useIamService_frontend',
    businessUser: {
      deleteBusinessUser: 'admin_business_user_delete_business_user',
    },
  },
} as const;

const practiceFeatureKeys = {
  practiceSearch: {
    usersAndRoles: 'practiceSearch_enableUsersAndRoles_frontend',
    showUserActions: 'practiceSearch_showUserActions_frontend',
    userRole: 'practiceSearch_enableUser_frontend',
    archiveUnarchiveLocations:
      'practiceSearch_archiveUnarchiveLocations_frontend',
  },
  practiceView: {
    usePricingAndPurchasing: 'practiceView_usePricingAndPurchasing_frontend',
    transitionP1AndP2: 'pricing_transitionP1AndP2_web',
    disableArchiveLocationButton: 'a4a_disable_archive_location_button',
  },
  termsAndConditionsBusinessLocationProfile:
    'admin_termsAndConditionsBusinessLocationProfile_frontEnd',
} as const;

const referAFriendFeatureKeys = {
  patientSummary: {
    showReferralInformation:
      'admin_patientSummary_showReferralInformation_frontend',
  },
} as const;

const transactionFeatureKeys = {
  patientFinanceRefund: 'transaction_patientFinanceRefund_web',
  cancelTransactionAfter48Hours: 'admin_cancelTransactionAfter48Hours_frontend',
} as const;

export {
  crFeatureKeys,
  practiceFeatureKeys,
  a4aFeatureKeys,
  paymentsFeatureKeys,
  referAFriendFeatureKeys,
  transactionFeatureKeys,
};
