import { Box } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { OfferBuilderPermission } from '../../../auth/permission';
import { useAlleIam } from '../../../auth/useAlleIam';
import { Routes } from '../../../constants';
import { useAdminFeatureFlags } from '../../../hooks/useAdminFeatureFlags';
import { usePaymentsAdminFeatureFlags } from '../../../hooks/usePaymentsAdminFeatureFlags';
import { useCanViewOfferDashboard } from '../../../views/offerBuilder/queries/useCanViewOfferDashboard';
import { Navigation } from '../navigation';

interface A4APageContainerProps {
  children: React.ReactNode;
}

const A4APageContainer: React.FunctionComponent<A4APageContainerProps> = ({
  children,
}) => {
  const { isEnabledPaymentsAdmin } = usePaymentsAdminFeatureFlags();
  const {
    isA4ABusinessPageViewEnabled,
    isOfferBuilderDomainOffersEnabled,
    isOfferFlex360TransferRebatesEnabled,
  } = useAdminFeatureFlags();
  const { isIamServiceEnabled } = useAdminFeatureFlags();
  const { isAllowed } = useAlleIam();
  let isOfferBuilderEnabled = useCanViewOfferDashboard();
  let isOfferFlexRebatesEnabled = isOfferFlex360TransferRebatesEnabled;

  if (isIamServiceEnabled) {
    isOfferBuilderEnabled =
      isOfferBuilderDomainOffersEnabled &&
      isAllowed(OfferBuilderPermission.READ);

    isOfferFlexRebatesEnabled =
      isOfferFlex360TransferRebatesEnabled &&
      isAllowed(OfferBuilderPermission.WRITE);
  }

  const getRoutes = useCallback(
    () =>
      [
        {
          title: 'Consumers',
          path: Routes.consumerSearch,
          visible: true,
        },
        {
          title: 'Businesses',
          path: isA4ABusinessPageViewEnabled
            ? Routes.a4aBusiness
            : Routes.providerUserSearch,
          visible: true,
        },
        {
          title: 'Gift Card Lookup',
          path: Routes.giftCardSearch,
          visible: true,
        },
        {
          title: 'Offer Builder',
          path: Routes.offerBuilderDashboard,
          visible: isOfferBuilderEnabled,
        },
        {
          title: 'Transfer Rebates',
          path: Routes.offerFlex360TransferRebates,
          visible: isOfferFlexRebatesEnabled,
        },
        {
          title: 'Payments Admin',
          path: Routes.paymentsAdmin,
          visible: isEnabledPaymentsAdmin,
        },
      ].filter((route) => route.visible),
    [
      isOfferBuilderEnabled,
      isA4ABusinessPageViewEnabled,
      isOfferFlexRebatesEnabled,
      isEnabledPaymentsAdmin,
    ]
  );

  const routes = useMemo(getRoutes, [
    isOfferBuilderEnabled,
    isEnabledPaymentsAdmin,
    getRoutes,
  ]);

  return (
    <Box
      flexDirection="column"
      data-testid="a4a-page-container"
      position="sticky"
      width="100%"
      top="0"
      zIndex={999}
    >
      <Navigation routes={routes} />
      {children}
    </Box>
  );
};

export { A4APageContainer };
